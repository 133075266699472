.ftv-dpad {
  svg {
    .button {
      fill-opacity: 0.3;
      transition: 0.2s ease-out;
    }

    .arrow {
      transition: 0.2s ease-out;
    }

    .center-group .base {
      transition: 0.2s ease-out;
    }

    .nav-group:active path {
      fill-opacity: 0.5;
    }

    .center-group:active .base {
      fill: #4A4A4A;
    }

    .center-group:active .gradient {
      fill: url(#paint0_linear_312_204);
    }
  }
}
