@import "styles/firetv";

.katal, body, #root {
    background-color: #1A1A1A;
    min-height: 100vh;
}

.katal .container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    margin: 10px 18px 7px 18px;
    padding: 0;
    width: 400px;
}

@media only screen and (max-width: 400px) {
   .katal .container {
       width: 100%;
    }
}
