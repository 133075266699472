.status-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  height: 50vh;
  color: #F0F0F0;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Amazon Ember", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.001px;
}