.connection-status {
  height: 30px;
  display: flex;
  justify-content: center;

  .status {
    font-family: "Amazon Ember", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #fff;
  }
}


.input-container {
  background-color: #2A2A2A;
  position: relative;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;

  .input-group {
    display: flex;
  }

  input {
    display: flex;
    width: 100%;
    background-color: transparent;
    border: none;
    color: #fff;
    padding: 24px 24px 10px 12px;
    border-bottom: 2px solid #C2C2C2;;

    font-family: "Amazon Ember", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.001px;
    transition: ease-in-out 0.2s;

    &:focus {
      outline: none;
      border-bottom-color: #FA6400;
    }

    &::placeholder {
      color: transparent;
    }
  }

  label {
    background-color: transparent;
    position: absolute;
    display: block;
    pointer-events: none;
    margin: 24px 12px 12px 12px;
    transform-origin: left center;
    transition: all ease-in-out 250ms;
    color: #C2C2C2;
    font-family: "Amazon Ember", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.001px;
    will-change: transform;
  }

  input:focus + label {
    color: #FA6400;
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    transform: translateY(-110%);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.001px;
  }

  .input:placeholder-shown + .label + .clear {
    display: none;
  }

  .icon {
    display: flex;
    position: absolute;
    color: #fff;
    right: 0;
    visibility: hidden;
    width: 28px;
    height: 54px;
    padding: 22px 4px 8px 0;
    align-items: flex-start;
    transition: ease-in-out 0.2s;
  }

  input:focus ~ .icon {
    visibility: visible;
  }

  .icon:active {
    fill-opacity: 0.5;
  }

}

.button-container {
  display: flex;
  height: 40px;

  .action-button {
    width: 100%;
    border-radius: 8px;
    border: none;
    background-image: linear-gradient(180deg, #F0F0F0 1.73%, #DADADA 100%);

    font-family: "Amazon Ember", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    text-transform: capitalize;
    opacity: 1;
    color: black;

    transition: 0.2s ease-out;

    &:active {
      opacity: 0.8;
    }
  }
}

.navigation-container {
  display: flex;
  justify-items: center;
  flex-direction: column;

  img {
    width: 100%;
  }
}

.banner-container {
  display: flex;
  padding: 0 24px;
  align-items: center;

  .appstore-link {
    color: var(--base-color-gray-50, #F0F0F0);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Amazon Ember", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.001px;
    margin: 0;
  }
}

.badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.show-button, .hide-button {
  margin-top: 4px;
}

.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}

.empty-input-container {
  height: 56px;
}

.empty-submit-container {
  height: 40px;
}